import {
  getPlatAll,
  getAppType,
  fileUploader,
  getIconAll,
  applicationUpdate,
  applicationAdd,
  fileDelete,
} from "@lib";
// import TextEditer from "@component/TextEditer/index.vue";
export default {
  components: {
    TextEditer: ()=>import("@component/TextEditer/index.vue"),
  },
  data() {
    return {
      ruleForm: {
        id: "",
        applyPlatId: "",
        categoryId: "",
        code: "",
        description: "",
        fullName: "",
        guide: "",
        icon: "",
        iconId: "",
        introduction: "",
        shortName: "",
        url: "",
        pageNumber: 1,
        pageSize: 10,
      },
      disabled: false,
      platArr: [],
      categoryArr: [],
      tit: "",
      Files: [],
      resId: "",
      dialogShow: false,
      iconArr: [],
      actIconInfo: "",
      rules: {
        applyPlatId: [
          { required: true, message: "请选择适用平台", trigger: "change" },
        ],
        categoryId: [
          { required: true, message: "请选择应用分类", trigger: "change" },
        ],
        iconId: [{ required: true, message: "请选择图标", trigger: "blur" }],

        code: [
          { required: true, message: "请填写应用编码", trigger: "blur" },
          //   { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        fullName: [
          { required: true, message: "请填写应用名称", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请填写应用简介", trigger: "blur" },
        ],
        guide: [{ required: true, message: "请填写应用指南", trigger: "blur" }],
        introduction: [
          { required: true, message: "请填写应用介绍", trigger: "blur" },
        ],
        shortName: [
          { required: true, message: "请填写显示名称", trigger: "blur" },
        ],
        url: [{ required: true, message: "请填写系统地址", trigger: "blur" }],
      },
    };
  },
  async created() {
    await getPlatAll().then((res) => {
      this.platArr = res.result;
      this.ruleForm.applyPlatId = res.result[0].id;
    });
    await getAppType().then((res) => {
      this.categoryArr = res.result;
      this.ruleForm.categoryId = res.result[0].id;
    });

    await getIconAll().then((res) => {
      this.iconArr = res.result;
      this.actIconInfo = res.result[0];

      this.ruleForm.iconId = this.actIconInfo.id;
      this.ruleForm.icon = this.actIconInfo.content;
    });

    let { info, editOrSee } = this.routerParams;

    if (info != undefined) {
      if (editOrSee) {
        this.tit = "修改";
        this.disabled = false;
      } else {
        this.tit = "浏览";
        this.disabled = true;
      }
      this.ruleForm = JSON.parse(info);
      console.log(this.ruleForm);
      this.actIconInfo = {
        iconDom: `<i class="iconfont">${this.ruleForm.icon}</i>`,
      };
    } else {
      this.disabled = false;
      this.tit = "新增";
    }
  },
  methods: {
    //   确认表单信息
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.id) {
            this.commitFN(applicationUpdate);
          } else {
            this.commitFN(applicationAdd);
          }
        } else {
          return false;
        }
      });
    },
    async commitFN(fnName) {
      await fnName(this.ruleForm).then((res) => {
        if (res.status === "success") {
          this.resId = res.result.id;
          let formD = new FormData();
          this.Files.forEach((item) => {
            formD.append("file", item);
          });
          if (this.Files.length) {
            fileUploader(formD, this.resId).then((res) => {
              if (res.success) {
                this.$message.success("操作成功");
                this.$router.back();
              }
            });
          } else {
            this.$message.success("操作成功");
            this.$router.back();
          }
        } else {
          this.$message.warning(res?.result?.error_msg || "操作失败");
        }
      });
    },
    handleRemove(file) {
      console.warn(file);
      this.Files.forEach((item, index) => {
        if (item == file.raw) {
          this.Files.splice(index, 1);
        }
      });
    },
    async beforeRemove(file, fileList) {
      try {
        await this.$confirm("确定删除图片？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        if (file.id) {
          const res = await fileDelete(file.id);
          return res.status === "success";
        } else {
          return false;
        }
      } catch (e) {
        file.status = `cancel-${fileList.findIndex(file)}`;
        return false;
      }
    },
    goodNewPicFn(val) {
      this.Files.push(val.file);
    },
    choiceFn(item) {
      this.actIconInfo = item;
      this.ruleForm.iconId = item.id;
      this.ruleForm.icon = item.content;
      this.dialogShow = false;
    },
    textareaData1(content) {
      console.log(111);
      this.ruleForm.introduction = JSON.parse(JSON.stringify(content));
    },
    textareaData2(content) {
      console.log(content);
      this.ruleForm.guide = JSON.parse(JSON.stringify(content));
    },
  },
};
